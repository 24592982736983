import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import GatsbyImage, { FluidObject } from "gatsby-image";

import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PartnerCard from "../components/PartnerCard";

import styles from "../styles/pages/parcerias.module.scss";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/SEO";

interface ImageFluid {
  publicURL: string;
  childImageSharp: {
    fluid: FluidObject;
  };
}
interface PageProps extends RouteComponentProps {
  data: {
    placeholderDish?: ImageFluid;
    clock?: { publicURL: string };
    pin?: { publicURL: string };
  };
}

const PartnershipPage: FunctionComponent<PageProps> = ({ data }) => {
  const renderPartners = () => {
    //TODO - fetch the actual list
    const result: JSX.Element[] = [];
    const entries = [];

    for (let i = 0; i < 8; i++) {
      entries.push({
        image: data.placeholderDish,
        clock: data.clock?.publicURL,
        pin: data.pin?.publicURL,
        rating: 4,
        totalReviews: 32,
        costRatingMin: 1,
        costRatingMax: 2,
        title: "TestTitle" + i,
        tags: ["tradicional", "italiana", "vegetariana"],
        workingHours: "00:00 - 12:00",
        distance: "500",
      });
    }

    entries.forEach((entry) => {
      result.push(
        <div className={styles.partner} key={entry.title}>
          <PartnerCard data={entry} />
        </div>
      );
    });

    return result;
  };

  const intl = useIntl();
  return (
    <Layout transparentHeader>
      <SEO
        title={intl.formatMessage({ id: "seo_title_parcerias" })}
        description={intl.formatMessage({ id: "seo_description_parcerias" })}
        keywords={intl.formatMessage({ id: "seo_keywords_parcerias" })}
        canonical_url={`https://take-eat.pt/parcerias`}
        image={`https://take-eat.pt${data.placeholderDish?.publicURL}`}
      />
      <div className={styles.pageWrapper}>
        <h1>{intl.formatMessage({ id: "partnerPage_welcome_title" })}</h1>
        <p>{intl.formatMessage({ id: "partnerPage_welcome_subTitle" })}</p>
        <div className={styles.partnerList}>{renderPartners()}</div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    placeholderDish: file(
      relativePath: { eq: "pages/parcerias/placeholder-lasagna.jpg" }
    ) {
      publicURL
      childImageSharp {
        fluid(quality: 90) {
          src
          tracedSVG
        }
      }
    }
    clock: file(relativePath: { eq: "icons/clock.svg" }) {
      publicURL
    }
    pin: file(relativePath: { eq: "icons/pin.svg" }) {
      publicURL
    }
  }
`;

export default PartnershipPage;
