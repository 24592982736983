import React, { FunctionComponent } from "react";
import GatsbyImage, { FluidObject } from "gatsby-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as starSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as starEmpty } from "@fortawesome/free-regular-svg-icons";
import styles from "./styles.module.scss";

interface ImageFluid {
  publicURL: string;
  childImageSharp: {
    fluid: FluidObject;
  };
}
interface ComponentProps {
  data: {
    image?: ImageFluid;
    clock?: string;
    pin?: string;
    rating: number;
    totalReviews: number;
    costRatingMin: number;
    costRatingMax: number;
    title: string;
    tags: Array<string>;
    workingHours: string;
    distance: string;
  };
}

const PartnerCard: FunctionComponent<ComponentProps> = ({ data }) => {
  const ratingStars = () => {
    const result = [];
    for (let i = 0; i < 5; i++) {
      if (i < data.rating) {
        result.push(
          <FontAwesomeIcon className={styles.coloredIcon} icon={starSolid} />
        );
      } else {
        result.push(<FontAwesomeIcon icon={starEmpty} />);
      }
    }
    return result;
  };

  const ratingCostString = () =>
    ` (${data.totalReviews}) • ${"€".repeat(data.costRatingMin)} - ${"€".repeat(
      data.costRatingMax
    )}`;
  return (
    <div className={styles.card} key={data.title}>
      {/* <img src={data.image.publicURL} alt="" /> */}
      <GatsbyImage
        fadeIn
        alt=""
        fluid={data.image?.childImageSharp.fluid}
        loading="lazy"
        style={{
          width: "100%",
          height: "8rem",
          objectFit: "cover",
        }}
      />
      <div className={styles.content}>
        <p className={styles.spaced}>
          {ratingStars()}
          <span className={styles.fadedText}>{ratingCostString()}</span>
        </p>
        <h2>{data.title}</h2>
        <p className={`${styles.fadedText} ${styles.spaced}`}>
          {data.tags.join(" • ")}
        </p>
        <div className={styles.bottomContent}>
          <span>
            <img
              style={{ width: 16, height: 16, marginRight: 2 }}
              src={data.clock}
              alt="opening time"
            />
            {data.workingHours}
          </span>
          <span>
            <img
              style={{ width: 16, height: 16, marginRight: 2 }}
              src={data.pin}
              alt="local"
            />
            {data.distance} m
          </span>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
